'use client';

import React from "react";
import { useSession } from "next-auth/react";
import { useRouter } from "next/navigation";
import OnboardingPage from "./onboarding-page";
import TopNavBar from "./TopNavBar";

export default function MainContent() {
  const [showOnboarding, setShowOnboarding] = React.useState(false);
  const [userData, setUserData] = React.useState({
    firstname: "",
    lastname: "",
    email: "",
    phone: "",
    shopname: "",
    subdomain: "",
    timezone: "",
    timestamp: ""
  });
  
  const router = useRouter();
  const { data: session, status } = useSession();

  React.useEffect(() => {
    console.log('Session changed:', session);
    if (session?.user?.email && session?.user?.shop_id) {
      fetchUserData(session.user.email, session.user.shop_id);
    }
  }, [session]);

  const fetchUserData = async (email, shopId) => {
    try {
      console.log('Fetching user data for email:', email);
      const response = await fetch('/api/call-user-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ email, shopId }),
      });
      console.log('Response status:', response.status);
      const data = await response.json();
      console.log('Received data:', data);

      if (response.ok && data.success) {
        console.log('User found, setting user data');
        setUserData(data.userData);
      } else {
        console.log('User not found or error, showing onboarding');
        setShowOnboarding(true);
      }
    } catch (error) {
      console.error('Error fetching user data:', error);
      setShowOnboarding(true);
    }
  };

  const handleOnboardingSubmit = async (formData) => {
    try {
      const response = await fetch('/api/insert-user-data', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify(formData),
      });

      const data = await response.json();

      if (response.ok) {
        console.log('User data inserted successfully');
        setUserData(formData);
        setShowOnboarding(false);
      } else {
        console.error('Failed to insert user data:', data.error);
        // Optionally, show an error message to the user
      }
    } catch (error) {
      console.error('Error submitting onboarding data:', error);
      // Optionally, show an error message to the user
    }
  };

  return (
    <div className="min-h-screen bg-gray-100">
      {status === "authenticated" && <TopNavBar />}
      {showOnboarding && status === "authenticated" && (
        <div className="fixed inset-0 bg-gray-600 bg-opacity-50 z-50 flex items-center justify-center">
          <OnboardingPage onSubmit={handleOnboardingSubmit} userEmail={session?.user?.email} />
        </div>
      )}
      {status === "authenticated" ? (
        <main className="w-[90%] mx-auto py-6 sm:px-6 lg:px-8">
          <h1 className="text-3xl font-bold mb-4">Dashboard</h1>
          <div className="bg-white shadow rounded-lg p-6">
            <p>Welcome, {userData.firstname || session.user.email}!</p>
            <p>This is your dashboard content.</p>
          </div>
        </main>
      ) : status === "loading" ? (
        <div className="flex justify-center items-center h-screen">
          <p>Loading...</p>
        </div>
      ) : (
        <div className="px-4 py-6 sm:px-0">
          <h2 className="text-2xl font-semibold text-gray-700 mb-4">
            Please sign in to view your Dashboard
          </h2>
          <button
            onClick={() => router.push('/signin')}
            className="bg-blue-500 hover:bg-blue-600 text-white font-bold py-2 px-4 rounded"
          >
            Sign in
          </button>
        </div>
      )}
    </div>
  );
}
