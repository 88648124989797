"use client";
import React from "react";

function OnboardingPage({ onSubmit, userEmail, onComplete }) {
  const [formData, setFormData] = React.useState({
    firstname: "",
    lastname: "",
    email: userEmail || "",
    phone: "",
    shopname: "",
    subdomain: "",
    timestamp: new Date().toISOString(),
    timezone: Intl.DateTimeFormat().resolvedOptions().timeZone,
  });

  const [isSubmitting, setIsSubmitting] = React.useState(false);

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'subdomain') {
      // Remove '.booksini.com' if present and any non-alphanumeric characters
      const cleanedSubdomain = value.replace('.booksini.com', '').replace(/[^a-zA-Z0-9]/g, '');
      setFormData(prevData => ({
        ...prevData,
        [name]: cleanedSubdomain
      }));
    } else {
      setFormData(prevData => ({
        ...prevData,
        [name]: value
      }));
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSubmitting(true);
    try {
      const response = await fetch('/api/insert-user-data', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify(formData),
      });

      if (response.ok) {
        // Call the onSubmit prop function with the form data
        await onSubmit(formData);
        // Call the onComplete function to handle navigation
        onComplete();
      } else {
        console.error('Failed to submit onboarding data');
        // Optionally, you can show an error message to the user here
      }
    } catch (error) {
      console.error('Error submitting onboarding data:', error);
      // Optionally, you can show an error message to the user here
    } finally {
      setIsSubmitting(false);
    }
  };

  return (
    <div className="fixed inset-0 flex items-center justify-center bg-gradient-to-br from-blue-500 to-purple-600">
      <div className="bg-white rounded-lg shadow-xl p-8 w-full max-w-md">
        <h1 className="text-3xl font-bold mb-6 text-center font-roboto">
          Welcome Onboard!
        </h1>
        <form onSubmit={handleSubmit}>
          <div className="mb-4">
            <label htmlFor="firstname" className="block text-sm font-medium text-gray-700 font-roboto">
              First Name
            </label>
            <input
              type="text"
              id="firstname"
              name="firstname"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="lastname" className="block text-sm font-medium text-gray-700 font-roboto">
              Last Name
            </label>
            <input
              type="text"
              id="lastname"
              name="lastname"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              onChange={handleChange}
            />
          </div>
          <div className="mb-4">
            <label htmlFor="email" className="block text-sm font-medium text-gray-700 font-roboto">
              Email
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              disabled
              className="mt-1 block w-full rounded-md border-gray-300 bg-gray-200 text-gray-500 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
          <div className="mb-4">
            <label htmlFor="phone" className="block text-sm font-medium text-gray-700 font-roboto">
              Phone
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <select className="inline-flex items-center px-3 rounded-l-md border border-r-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                <option>+1</option>
                <option>+44</option>
                <option>+91</option>
              </select>
              <input
                type="tel"
                id="phone"
                name="phone"
                required
                className="flex-1 block w-full rounded-none rounded-r-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                onChange={handleChange}
              />
            </div>
          </div>
          <div className="mb-4">
            <label htmlFor="shopname" className="block text-sm font-medium text-gray-700 font-roboto">
              Shop Name
            </label>
            <input
              type="text"
              id="shopname"
              name="shopname"
              required
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              onChange={handleChange}
            />
          </div>
          <div className="mb-6">
            <label htmlFor="subdomain" className="block text-sm font-medium text-gray-700 font-roboto">
              Subdomain
            </label>
            <div className="mt-1 flex rounded-md shadow-sm">
              <input
                type="text"
                id="subdomain"
                name="subdomain"
                required
                value={formData.subdomain}
                onChange={handleChange}
                className="flex-1 block w-full rounded-l-md border-gray-300 focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
              <span className="inline-flex items-center px-3 rounded-r-md border border-l-0 border-gray-300 bg-gray-50 text-gray-500 text-sm">
                .booksini.com
              </span>
            </div>
            <p className="mt-2 text-sm text-gray-600 font-roboto">
              Full URL: <span className="font-semibold">{formData.subdomain ? `${formData.subdomain}.booksini.com` : "Enter subdomain"}</span>
            </p>
          </div>
          <button
            type="submit"
            disabled={isSubmitting}
            className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded focus:outline-none focus:shadow-outline font-roboto flex items-center justify-center"
          >
            {isSubmitting ? (
              <>
                <svg className="animate-spin -ml-1 mr-3 h-5 w-5 text-white" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
                  <circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
                  <path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
                </svg>
                Please wait
              </>
            ) : (
              'Get Started'
            )}
          </button>
        </form>
      </div>
    </div>
  );
}

function OnboardingPageStory() {
  const mockOnSubmit = async (data) => {
    console.log("Form submitted with data:", data);
    return { success: true };
  };

  return (
    <div className="p-4">
      <h2 className="text-2xl font-bold mb-4 font-roboto">Onboarding Page</h2>
      <OnboardingPage onSubmit={mockOnSubmit} userEmail="user@example.com" />
    </div>
  );
}

export default OnboardingPage;
